*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }
  
  .otp-line{
      text-align: left;
      color: #3967E9;
      font-size: 20px;
  }
  
  /* .otp-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
  } */
  
  .ourtp-container{
    width: 100%;
    padding: 1rem 2rem;
  }
  
  .ourtp {
    display: flex;
    flex-direction: column;
    width:100%;
    overflow-x: hidden;
  }
    .clg-logo {
      display: flex;
      justify-content: space-between;
      width: 90%;
      align-self: center;
      margin: 3rem 0;
    }
  
    .icon-logos{
      max-width: 10rem;
    }
    .icon-logo{
      max-width: 10rem;
    }
   
    .otp-title{
      text-align: left;
      font-size: 38px;
    }
  
    
    .words1{
      font-size: 3.5vw;
      text-align: left;
      margin:0 0 1rem 0;
    }
   .button--calypso{
    /* border-radius: 2rem; */
    /* padding: 1rem 3rem; */
    background-color: #3967E9;
    color: #FCFFFF;
    border:none;
    cursor: pointer;
    outline: none; 
    font-size: 1rem;
  
  }
  .button--calypso:hover > .a-icon{
    transform: rotate(45deg);
  }
  
  
   .gb-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    
   }
  .grow-buisness {
      display: flex;
      justify-content: space-between;
      width: 90%;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  
    .sentence{
      line-height: 1.5;
    }
  
    .blank-img{
      width: 80%;
      overflow-x: hidden;
      
    }
    .button-container{
      width: 100%;
    }
    
    .button--calypso > span, .a-icon{
      vertical-align: middle;
    }
    .ourtp-text{
      margin:2rem 0 0 3rem;
    }
    .contents {
      width: 100%;
      max-width: 1300px;
      justify-content: center;
      list-style: none;
    }
    .content__item {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      counter-increment: itemcounter;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1;
      pointer-events: none;
    }
    
    .content__item::before {
      /* color: var(--color-number); */
      position: absolute;
      top: 0;
      left: 0;
      /* content: counters(itemcounter, ".", decimal-leading-zero); */
    }
    
    .button--calypso {
      pointer-events: auto;
      cursor: pointer;
      background: #3967E9;
      border: none;
      /* padding: 1.5rem 2rem; */
      margin: 0;
      font-size: 16px;
      position: relative;

      text-decoration: none;
      
    }
    
    .button--calypso::before,
    .button-.button--calypso {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .button--calypso {
      overflow: hidden;
      font-size: 1.15rem;
      /* border-radius: 2rem; */
      color: #ffffff;
    }
    .content__item{
      color: #ffffff;
    }
    
    .button--calypso span {
      position: relative;
      z-index: 10;
      text-decoration: none;
    }
    
    .button--calypso:hover span {
      animation: MoveScaleUpInitial 0.3s forwards, MoveScaleUpEnd 0.3s forwards 0.3s;
    }
    
    .b-img{
      width: 100%;
      box-shadow: 5px 5px 5px rgba(0.5, 0.5, 0.6, 0.5);
      border-radius: 20px;
      /* padding-left: -40px; */
    }
    @keyframes MoveScaleUpInitial {
      to {
        transform: translate3d(0,-105%,0) scale3d(1,2,1);
        opacity: 0;
      }
    }
    
    @keyframes MoveScaleUpEnd {
      from {
        transform: translate3d(0,100%,0) scale3d(1,2,1);
        opacity: 0;
      }
      to {
        transform: translate3d(0,0,0);
        opacity: 1;
      }
    }
    
    .button--calypso::before {
      content: '';
      background: #2b57d0;
      width: 120%;
      height: 0;
      padding-bottom: 120%;
      top: -100%;
      left: -10%;
      border-radius: 50%;
      transform: translate3d(0,68%,0) scale3d(0,0,0);
    }
    
    .button--calypso:hover::before {
      transform: translate3d(0,0,0) scale3d(1,1,1);
      transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    }
    
    .button--calypso::after {
      content: '';
      background: #2b57d0;
      transform: translate3d(0,-100%,0);
      transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    }
    
    .button--calypso:hover::after {
      transform: translate3d(0,0,0);
      transition-duration: 0.05s;
      transition-delay: 0.4s;
      transition-timing-function: linear;
    }
  
    .button-size-1{
      padding: 1.5rem 3rem;
      border-radius: 3rem;
    }
  
    @media screen and (max-width:834px) {


      .grow-buisness {
        display: flex;
        flex-direction: column;
        width: 90%;
        align-items: center;
      }
      .words{
        font-size: 36px;
      }
      .clg-logo{
        width: 80%;
      }
      
      .otp-container{
        width: 100%;
        padding: 0;
      }
      .icon-logos{
        max-width: 7rem;
      }
      .icon-logo{
        max-width: 7rem;
      }
      .blank-img{
        position: relative;
        width: 100%;
        /* overflow-x: hidden; */
      }
      .ourtp{
        width: 100%;
        margin:0;
      }
  
      .content__item{
        margin-bottom: 20px;
      }

      .words1{
        font-size: 1.5rem;
        font-weight: 700;
      }
      
     }
     @media screen and (max-width:430px){
        .clg-logo{
          flex-direction: column;
          align-items: center;
        }
        .b-img{
          /* height: 10rem; */
          /* width: 16rem; */
          border-radius: 1.25rem;
        }
        .otp-line{
          margin-left: -13%;
        }
        .otp-title {
          font-size: 30px;
          /* margin-left: -13%; */
        }
        .icon-logos{
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
        .icon-logo{
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      
        .words1{
      
          font-size: 2rem;
          font-weight: 700;
        }
       
        .button-size-1{
          padding: 1rem 1.5rem;
          border-radius: 3rem;
          font-size: 1rem;
        }
        .grow-buisness{
          display: flex;
          flex-direction: column;
          align-items: center;
          
        }
        .button-size-1{
          padding: 1rem 1.5rem;
          border-radius: 3rem;
        }
  
     }
     