.crewcard-main-container{
  width: 90%;
}

.about-mfai-subtitle-crewcards{
  width: max-content;
  border-radius: 1.25rem;
  border: 1px solid grey;
  border-style: dashed;
  margin: 1rem 0;
  margin-left: 2rem;
}

.about-mfai-subtitle-crewcards > button{
  font-size: 2vmin;
  padding: 1rem;
  border: none;
  border-radius: 1.25rem;
  font-family: "Poppins", sans-serif;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #3967E9;
}



.parent {
    width: 290px;
    height: 300px;
    perspective: 1000px;
    font-family: "Craftwork Grotesk";
  }
  

  .card {
    position: relative; 
    overflow: hidden;
    height: 100%;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    transform-style: preserve-3d;
    box-shadow: rgba(5, 71, 17, 0) 40px 50px 25px -40px, rgba(5, 71, 17, 0.2) 0px 25px 25px -5px;
}

.card::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px; 
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); 
    border-radius: 50px;
}

  .card > p {
    margin-top: -30px;
    z-index: 2;
  }
  
  .glass {
    transform-style: preserve-3d;
    position: absolute;
    inset: 8px;
    border-radius: 55px;
    border-top-right-radius: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.349) 0%, rgba(255, 255, 255, 0.815) 100%);
    transform: translate3d(0px, 0px, 25px);
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    transition: all 0.3s ease-in-out;
  }
  
  .content >img {
    width:250px;
    margin-top: -115px;
  }
  
  .content {
    padding: 100px 60px 0px 30px;
    transform: translate3d(0, 0, 26px);
  }
  
  .content .title {
    display: block;
    color: #00894d;
    font-weight: 900;
    font-size: 20px;
  }
  
  .content .text {
    display: block;
    color: black;
    font-size: 15px;
    margin-top: 20px;
  }
  
  .bottom {
    /* padding: 10px 12px; */
    /* padding-right: 100px; */
    transform-style: preserve-3d;
    position: absolute;
    bottom: 20px;
    left: 20px;
    /* right: 20px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: translate3d(0, 0, 26px);
    z-index: 1;
  }
  
  .bottom .view-more {
    display: flex;
    align-items: center;
    width: 40%;
    justify-content: flex-end;
    transition: all 0.2s ease-in-out;
  }
  
  .bottom .view-more:hover {
    transform: translate3d(0, 0, 10px);
  }
  
  .bottom .view-more .view-more-button {
    background: none;
    border: none;
    color: black;
    font-weight: bolder;
    font-size: 12px;
  }
  
  .bottom .view-more .svg {
    fill: none;
    /* stroke: #00c37b; */
    max-height: 15px;
  }
  
  .bottom .social-buttons-container {
    /* display: flex; */
    /* gap: 10px; */
    transform-style: preserve-3d;
    margin-top: -430px;
    /* border: 20x solid black; */
    margin-left: 20px;
  }
  
  .bottom .social-buttons-container .social-button {
    width: 30px;
    height: 30px;
    aspect-ratio: 1;
    padding: 5px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    border: none;
    display: grid;
    place-content: center;
    box-shadow: rgba(5, 71, 17, 0.5) 0px 7px 5px -5px;
  }

/* .details{
  border: 2px solid black;
} */
  .details >h3{
    margin-left: -50px;
    text-decoration: underline;
  }
  .details >p{
    margin-left: -50px;
    /* border: 2px solid black; */
    /* text-decoration: underline; */
  }

  .bottom .social-buttons-container .social-button:first-child {
    transition: transform 0.2s ease-in-out 0.1s, box-shadow 0.2s ease-in-out 0.1s;
    margin-top: 1%;
  }
    
  .bottom .social-buttons-container .social-button:hover {
    background: #0077b5;
    transition: 0.3s ease-in-out;
  }
  
  
  .social-button:hover  .linkedin_icon{
    filter: invert(100%) !important;
  }
  
  .linkedin_icon{
    margin-top: 5px;
    transition: filter 0.5s ease;
  }

  .social-button .social-button1{
    height: 20px;
    width: 20px;
  }

  .logo {
    position: absolute;
    right: 0;
    top: 0;
    transform-style: preserve-3d;
  }

  
  .logo .circle {
    display: block;
    position: absolute;
    aspect-ratio: 1;
    border-radius: 50%;
    top: 30px;
    right: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) -10px 10px 20px 0px;
    background: white;
    transition: all 0.3s ease-in-out;
  }
  
  .logo .circle5 {
    width: 50px;
    transform: translate3d(0, 0, 100px);
    top: 18px;
    /* right: 20px; */
    display: grid;
    place-content: center;
    transition-delay: 0.3s;
  }
  
  .logo .circle5 > img {
    width: 30px;
    height: 30px;
    margin-left: -4px;
  }
  
  .parent:hover .card {
    transform: rotate3d(1, 1, 0, 30deg);
    box-shadow: rgba(5, 71, 17, 0.3) 30px 50px 25px -40px, rgba(5, 71, 17, 0.1) 0px 25px 30px 0px;
  }
  
  .parent:hover .card .bottom .social-buttons-container .social-button {
    transform: translate3d(0, 0, 50px);
    box-shadow: rgba(5, 71, 17, 0.2) -5px 20px 10px 0px;
  }

  .crew-member-cards-grid{
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 3rem;
    row-gap: 3rem;
    align-self: center;
    filter: drop-shadow(0 0 0.75rem rgb(95, 139, 227));
  }

  
  @media (min-width: 360px) and (max-width: 435px) {
    .crew-member-cards-grid {
      margin-top: 57px;
      display: flex;
      flex-wrap: nowrap;
      gap: 50px;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      justify-content: center;
      -webkit-overflow-scrolling: touch;
      padding: 0 10px; 
      margin-left: 50px;
    }

    .parent {
      flex: 0 0 auto;
      scroll-snap-align: center;
      min-width: 298px;
      margin: 0 10px; 
  }
    
      .parent:first-child {
        margin-left: calc(50vw - (-400rem) );
      }
    
      .parent:last-child {
        margin-right: calc(50vw - 149px); 
      }
    
      .crew-member-cards-grid::-webkit-scrollbar {
        display: none;
      }
  }