.scheduling{
    margin: 20px 0;
    display: flex;
    row-gap: 9px;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;
    width: 80%;
  }
  
  .main{
    border: 2px solid black;
    padding: .5rem;
    border-radius: 24px;
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
  }



  .ccontent>.first{
    font-family: "Poppins", sans-serif;
   font-weight: 700;
   text-align: center;
   padding-top: 1rem;
  }
  
  .ccontent>.second{
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 24px;
    color:  #121212;
    display: flex;
    justify-content: center;
    column-gap: 16px;
    padding: 1rem 0;
   }
  
  
   .second > img{
    width: 38px;
   }
  
  
  
  @media only screen and (min-width:360px) and (max-width: 490px) {
    .calendarmain{
     width: 320px;
    }
    
    .scheduling{
      flex-direction: column;
    }

    .calendar-header, .month-picker {
       font-size: 1rem;
   }
   .calendar-days div.calendar-day{
     width: 30px;
     height: 30px;
   }
 
   .calendar-body{
     padding: 1rem;
   }
 
 } @media only screen and (min-width:491px) and (max-width: 900px) {
  .scheduling{
    flex-direction: column;
  }

  .calendarmain{
    width: 350px;
   }
 }
 