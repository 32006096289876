body{
    font-family: "Poppins", sans-serif;
  }
  
  .fwc{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 907px;
   padding: 3rem 0;
  }
  
  .wculture-wrap{
    background-color: #e0dbdb;
    display: flex;
    justify-content: center;
  }
  
  .whead{
    text-align: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
  }
  
  .wccontent{
     margin: 1rem 0;
     font-size: 18px;
     text-align: justify;  
     color: #2b2b2b;    
     width: 70%;
     line-height: 1.5;
  }
   .head{
     font-size: 2rem;
     color: #3967E9;
     font-weight: 500;
   }
  .heads{
     font-size:38px;
     color: #2b2b2b; 
     /* margin-top: 5px;  */
  }
  .wc{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #e0dbdb;
    /* padding-bottom: 3rem; */
  }
  .whead{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 90%; */
  }
  
  .animate {
    justify-content: center;
    align-items: center;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .animate img {
    width: 50%;
    margin-left: 14rem;
    margin-top: 5px;
    border-radius: 20px;
    /* box-shadow: 5px 5px 5px rgba(0.5, 0.5, 0.6, 0.5); */
    filter: drop-shadow(0 0 0.75rem rgb(95, 139, 227));
  }
  
  /* .animate img {
    top: 2rem;
    left: 3rem;
    z-index: 0;
    margin-bottom: 20px;
  } */

  .mobile{
    display:none;
  }

  
  @media screen and (max-width:991px) and (min-width:768px){
  .heads{
    font-size: 28px;
  }
  .wccontent{
    line-height: 1;
    margin: 0.5rem;
  }

  .animate{
    width: 100%;
    height: 360px;
    margin-left: -5rem;
  }
  
  .animate img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .fwc{
    width: 100%;
    padding: 3rem 4rem;
  }
  
  .wccontent{
    width: 100%;
  }
  
  .web{
    display: none;
  }
  
  .mobile{
    display:block;
  }
  }
  
  @media screen and (max-width:834px){
    .mobile{
      display: block;
    }
    
    .web{
      display:none;
    }
  
    .wculture-wrap{
      padding: 0px 24px;
    }
  
    .fwc{
      width: 100%;
      padding-bottom: 4rem;
    }
  
    .wccontent{
      width: 100%;
    }
  
    .wccontent p{
      text-align: center;
    }
    .animate img {
      margin-left: -1%;
      width: 100%;
    }
  
  }
  @media screen and (max-width:430px){
    .heads{
      font-size: 24px;
    }
    .wccontent{
      line-height: 1;
      margin: 0.5rem;
    }
    .wccontent p{
      text-align: justify;
    }
    .animate img{
      margin-left: .25px;
      height: 100%;
      width: 100%;
    }
    
  }
  
   
    
    
    
  
  /* Add similar positioning for the rest of the images */
  
  
  
  