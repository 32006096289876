body {
  background: #e8e8e8;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  color: #212121;
}

.spacer {
  width: 100%;
  /* height: 90vh; */
  margin-top: 35rem;
  margin-bottom: -40%;
}

.gallery h1 {
  font-size: clamp(2em, 3vw, 4em);
  left: 30px;
}

.home-service-container {
  display: flex;
  justify-content: center;
}
.home-services {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  width: 90%;
}

.home-services p,
h1 {
  margin-bottom: 1rem;
}

.service-box {
  font-size: 14px;
  position: relative;
  display: flex;
  padding: 0.7rem 0rem 0.7rem 0.5rem;
  background-color: #d9d9d9;
  width: max-content;
  border-radius: 1.25rem;
  border: 1.5px dashed #121212;
  justify-content: space-between;
  z-index: 10;
}

.temp {
  display: none;
}

.service-box.fixed {
  position: fixed;
  top: 150px; /* Adjust this as needed */
  /* z-index: 9; Ensure it stays above other elements */
}

.service-box div {
  padding: 1rem;
  background-color: white;
  border-radius: 1rem;
  font-weight: 500;
  margin-right: 0.5rem;
  cursor: pointer;
}

.service-box div:last-child {
  padding: 1rem;
  background-color: white;
  border-radius: 1rem;
  font-weight: 500;
}
.service-box span:hover {
  background-color: #4671e5;
}

.gallery p {
  font-size: 24px;
  line-height: 1.4;
  padding-top: 30px;
  text-align: justify;
}

.gallery {
  display: flex;
  width: 90%;
  justify-content: space-between;
  /* outline:1px solid red; */
  /* padding-left: 8rem; */
}

.left {
  width: 70%;
  margin-bottom: 11%;
}

.right {
  height: 100vh;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.desktopContent {
  margin: auto;
  width: 80%;
}

.desktopContentSection {
  min-height: 60vh;
  /* outline:1px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.service-link.active {
  background-color: #5579dd;
}

.desktopPhotos {
  margin-top: 30%;
  width: 30vw;
  height: 28vw;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  /* box-shadow: 5px 5px 5px rgba(0.5, 0.5, 0.6, 0.5); */
  filter: drop-shadow(0 0 0.75rem rgb(95, 139, 227));
}

.desktopPhoto {
  position: absolute;
  width: 100%;
  height: 100%;
}

.red {
  background-image: url('../Section2/assests/ML.jpeg.jpg');
  background-size: cover;
}

.green {
  background-image: url('../Section2/assests/GENAI.jpeg.jpg');
  background-size: cover;
}

.blue {
  background-image: url('../Section2/assests/MERN.jpeg.jpg');
  background-size: cover;
}

.pink {
  background-image: url('../Section2/assests/UIUX.jpeg.jpg');
  background-size: cover;
}

.grey {
  background-image: url('../Section2/assests/Cloud.jpeg');
  background-size: cover;
}

/* small screen / mobile layout */
.mobileContent {
  display: none;
  width: 80vw;
}

.mobilePhoto {
  width: 80vw;
  height: 80vw;
  margin-top: 5em;
  border-radius: 6vw;
}
.mobilephoto p {
  text-align: justify;
}
.home-mfai-subtitle{
  width: max-content;
  border-radius: 1.25rem;
  border: 1px solid grey;
  border-style: dashed;
  margin: 1rem 0;
}
.home-mfai-subtitle > button{
  font-size: 2vmin;
  padding: 1rem;
  border: none;
  border-radius: 1.25rem;
  font-family: "Poppins", sans-serif;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #3967E9;
}


/* defines styles for screens up to 599px wide */
@media screen and (max-width: 834px) {
  .service-box {
    display: none;
  }

  .spacer {
    margin-bottom: -100%;
  }
  .gallery {
    display: flex;
    /* margin-left: -9rem; */
    /* margin-top: -5rem; */
    gap: 10px;
    align-items: center;
  }
  .left {
    display: none;
  }
  .right {
    height: auto;
    width: 100%;
    align-items: center;
  }

  .desktopPhotos {
    display: none;
    width: 50vw;
    height: 50vw;
  }

  .mobileContent {
    display: block;
    height: auto;
    /* width: 80%; */
    margin-left: 9%;
  }
  .mobilePhoto {
    /* width: 50vw; */
    height: 50vw;
    /* margin-left: 20%; */
    margin-bottom: 2em;
    margin-top: 2em;
    filter: drop-shadow(0 0 0.75rem rgb(180, 182, 255));
  }
  .gallery h1 {
    font-size: clamp(1.7em, 2vw, 3em);
    left: 30px;
  }
  .gallery p {
    font-size: 18px;
    line-height: 1.4;
    padding-top: 30px;
  }
}

@media screen and (max-width: 430px) {
  .spacer {
    margin-top: 30rem;
    margin-bottom: -70%;
  }
  .gallery p {
    font-size: 0.8rem;
    line-height: 1.4;
    padding-top: 30px;
  }

  .service-box {
    display: none;
  }
}

.fixed-button {
  position: fixed;
  top: 160px;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s, visibility 0.5s;
}

.hide {
  /* display: none; */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s, visibility 0.1s;
}
