
/* Define other @font-face rules similarly */

* {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}

.contact-us {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    justify-content: center; /* Center items horizontally */
    margin-top: 9%;
    /* border-radius:38px;
    border: 2px solid rgb(26, 24, 24); */
    width: 80%;
    margin-left: 10%;
    /* box-shadow: 5% black; */
}


.errory {
    color: red !important; 
    font-size: 0.9em !important;
    margin-top: -10px !important;
  }

.map-container {
    width: 40%; 
    height: 70%;
    margin-top: 7rem; /* Adjust margin-top as needed */
    order: 2;/* Display below the form on smaller screens */
    border-radius: 35px;
    border: 2px solid rgb(26, 24, 24);
}
.map-text{
    margin-left: 3%;
    font-size: 2.5rem;
    font-family: 'Poppins';
    font-weight: 600;
}
.map {
    width: 100%;
    height: 50%;
    border-radius: 30px;
    margin-top: 2%;
}

.contact-us-form {
    width: 50%; /* Take up full width on smaller screens */
    /* max-width: 400px;  */
    padding: 20px;
    margin-right: 10%;
    border-radius:38px;
    border: 2px solid rgb(26, 24, 24);
}

.contact-us-form h1 {
    font-size: 36px; /* Adjust font size for smaller screens */
    font-weight: 650;
}

.contact-us-form h5 {
    font-size: 18px; /* Adjust font size for smaller screens */
}

.contact-us-form p {
    font-size: 16px; /* Adjust font size for smaller screens */
}

.contact-us-form form input,
.contact-us-form form textarea {
    width: 100%;
    margin: 10px 0;
    padding: 9px;
    background: none;
    border: none;
    border-radius: 1px;
    outline: none;
    border-bottom: 1px solid #2C8DFF;
    color: black;
    font-size: 16px; /* Adjust font size for smaller screens */
}
.contact-us-form p{
    color: black;
    font-weight: 500;
    padding: 1rem 0 0 .5rem;

}


.contact-us-form form input::placeholder
{
    color: black;
    font-weight: 500;
} 

.contact-us-form form textarea {
    border: 1px solid  #2C8DFF;
    height: 100px; /* Adjust height as needed */
}

.contact-us-form form button {
    width: 100%;
    margin: 20px 0;
    padding: 16px;
    font-size: 20px;
    border-radius: 58px;
    background: linear-gradient(to bottom, #6E75F9, #2B34D9);
    color: white;
    border: none;
    cursor: pointer;
}

.contact-us-form form button:hover {
    background: linear-gradient(to bottom, #7b82f8, #232ab8);
}

.socialmedia {
    font-size: 40px;
    padding: 10px;
    cursor: pointer;
}

.submit-button-location{
    padding-left: 2.5rem;
}

.location-button{
    width: 90%;
    margin: 20px 0;
    padding: 16px;
    font-size: 20px;
    border-radius: 58px;
    background: linear-gradient(to bottom, #6E75F9, #2B34D9);
    color: white;
    border: none;
    cursor: pointer;
}

.submit-button-location :hover{
    background: linear-gradient(to bottom, #7b82f8, #232ab8);
}
/* Media Queries for Responsive Design */

@media screen and (max-width: 768px) {
    .map-container {
        width: 80%;
        order: 1;
    }
    .contact-us{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    
    .contact-us-form,.map-container {
        width: 80%;
    }    
}

@media screen and (max-width: 480px) {
    .map-container {
        order: 1;
        width: 80%;
        max-height: 50%;
    }

    .contact-us{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contact-us-form,.map-container {
        width: 80%;
    }
}


