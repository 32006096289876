*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.section-meet{
    /* display: flex; */
    width:90%;
    margin: 5rem  0;
    display: flex;
    justify-content: center;
}

.container-meet{
    display: flex;
}

.content-meet{
    font-family: "Poppins", sans-serif;
    font-size: 2.1vw;
    font-weight:400px ;
    text-align: left;
    line-height: 1.3;
}

.cm-text{
    margin:1rem 0;
}
.content-meet > button{
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: space-between;
    border-radius: 1.25rem;
    border: none;
    background-color: #3967E9;
    font-weight: 400px;
    padding: 1rem 2rem;
    cursor: pointer;
}

.content-meet-button > img{
    max-width: 2rem;
    margin-left: 1rem;
    /* vertical-align: middle; */
}
.content-meet-button > span{
    font-family: "Poppins", sans-serif;
    color: rgb(255, 255, 255);
    font-size: 1.5vw;
    font-weight: 500;
    vertical-align: middle;
    text-decoration: none;
    text-align: center;
    align-items: center;
}

.image-meet > img{
    max-width: 80%;
    height: 20rem;
    width: 75rem;
    margin:1rem;
}



@media only  screen and (max-width: 830px){
    .container-meet{
        flex-direction: column;
        width: 90%;
    }
    .content-meet-button{
        margin:1.5rem 0;
    }
    .content-meet-button > span{
        font-size: 1.2rem !important;
    }

    .image-meet > img{
        height: 15rem;
        width: 30rem;
    }
}



@media only screen and (min-width: 360px) and (max-width: 430px){
    .container-meet{
    flex-direction: column;
    width: 90%;
    }
    .cm-text{
        font-size: 1.5rem;
    }
    .content-meet-button > span{
        font-size: .5rem;
    }
    .content-meet-button{
        padding: 1rem !important;
        margin-left: 5rem;
    }
}

