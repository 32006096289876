/* @font-face {
    font-family: 'Craftwork Grotesk Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Craftwork Grotesk Regular'), url('../fonts/CraftworkGrotesk-Regular.ttf') format('woff');
  }
  
  @font-face {
    font-family: 'Craftwork Grotesk Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Craftwork Grotesk Medium'), url('../fonts/CraftworkGrotesk-Medium.ttf') format('woff');
  }
  
  
  @font-face {
    font-family: 'Craftwork Grotesk Semi Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Craftwork Grotesk Semi Bold'), url('../fonts/CraftworkGrotesk-SemiBold.ttf') format('woff');
  }
  
  @font-face {
    font-family: 'Craftwork Grotesk Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Craftwork Grotesk Bold'), url('../fonts/CraftworkGrotesk-Bold.ttf') format('woff');
    }
        
    
  @font-face {
    font-family: 'Craftwork Grotesk Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Craftwork Grotesk Heavy'), url('../fonts/CraftworkGrotesk-Heavy.ttf') format('woff');
    } */

.footer{
    background-color: black;
    font-family: "Poppins", sans-serif;
    padding: 96px 0px;
}
.footer_container{
    max-width: 1148px;
    padding: 0 16px;
    margin: 0 auto;
}
.footer_content{
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px 12px;
    flex-wrap: wrap;
}
.footer_links{
    flex-basis: 47.4%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-self: stretch;
    gap: 32px;

}
.footer_links h3{
    font-size: 16px;
    color: #d4dcd0;
    font-weight: 400;
    text-align: left;
}
.footer_links ul{
    display: flex;
    align-items: center;
    gap:32px;
    padding-top: 32px;
}
.footer_links li a{
    display: block;
    height: 24px;

}

/* .footer_links ul a:hover path {
    fill: gray;
    transition: all .3s ease-in-out;
    } */
.footer_address{
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: left;
    }
.footer_address p{
    font-weight: 400;
    font-size: 20px;
    color: #fafbfc;
    text-align: left;
    line-height: 150%;
    }
.footer_address a{
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 132%;
    letter-spacing: 4px;
    color: #b4bcd0;
    position: relative;
    padding-bottom: 10px;
    width: -moz-fit-content;
    width: fit-content;
}
.footer_address a::after{
    content: "";
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: #b4bcd0;
    left: 0;
    transition: all .3s ease-in-out;
    }
    .footer_address a:hover:after {
        animation: footer_borderBottom__e0yXH .3s linear forwards;
    }
    .footer_copyright{
        padding-top: 24px;
        text-align: left;
    }
    .footer_copyright label{
        font-size: 14px;
        font-weight: 400;
        color: #c9d1d9;   
    }
    .footer_contact{
        display: flex;
        align-items: flex-end;
        min-height: 300px;
        flex-basis: 51%;
    }
    .footer_contact a {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 24px;
        color: #fafbfc;
        align-self: stretch;
        justify-content: flex-end;
        background: transparent;
        border: 1px solid #292b2f;
        transition: all .3s ease-in-out;
        flex-grow: 1;
        position: relative;
        flex-basis: 50%;
    }
    .footer_contact a:first-child {
        border-right: none;
    }
    .footer_contact p {
        font-weight: 400;
        font-size: 20px;
        line-height: 132%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        justify-content: space-between;
    }
    .footer_contact a:after {
        background: url(https://i.ibb.co/G3Wc6DW/cardBg.png), linear-gradient(146.61deg, #202324 -1.27%, rgba(35, 35, 35, 0) 101.4%);
        width: 100%;
        height: 100%;
        content: "";
        z-index: -1;
        top: 0;
        opacity: 0;
        left: 0;
        transition: all .4s ease-in-out;
        position: absolute;
        background-repeat: no-repeat;
        background-position: 100% 100%;
        border-radius: 4px;
    }
    .footer_contact a:hover:after {
        opacity: 1;
        z-index: 1;
    }
    .footer_address a{
        text-decoration: none;
    }

    .teams-icon-card {
        width: 48px;
        height: 48px;
        cursor: pointer;
        /* background-color: #000; */
        /* background-image: linear-gradient(#242325, #171619); */
        border: 2px solid transparent;
        border-radius: 12px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        display: flex;
        position: relative;
        transform: none;
        box-shadow: inset 0 1px 2px #525154, 0 13px 10px rgba(0, 0, 0, .35);
        /* border-color: rgba(0, 0, 0, 0); */
        transform-style: preserve-3d;
        transition: background-image 0.3s;
    }
    .linkedin-icon {
        background-color: #000;
        /* background-image: linear-gradient(#242325, #171619); */
        border-color: rgba(0, 0, 0, 0);
    }
    
    .fb-icon {
        background-color: #000;
        /* background-image: linear-gradient(#242325, #171619); */
        border-color: rgba(0, 0, 0, 0);
    }
    
    .twitter-icon {
        background-color: #000;
        /* background-image: linear-gradient(#242325, #171619); */
        border-color: rgba(0, 0, 0, 0);
    }
    
    .insta-icon {
        background-color: #000;
        /* background-image: linear-gradient(#242325, #171619); */
        border-color: rgba(0, 0, 0, 0);
    }

    .linkedin-icon:hover {
        background-color: #0077b5; /* LinkedIn blue */
        border: none;
    }
    
    .fb-icon:hover {
        background-color: #395797; /* Facebook blue */
        border: none;
    }
    .twitter-icon:hover {
        border: none;
    }
    .insta-icon:hover {
        background-image: linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7); /* Instagram gradient */
        border: none;
    }

    @media screen and (max-width:767px) {
        .footer{
            padding: 48px 0px;
        }  
        .footer_container{
            max-width: 200%;
       }
       .footer_links{
            flex-basis: 100%;
       }
       .footer_contact{
            flex-basis: 100%;
            gap: 20px;
       }
       .footer_contact a:first-child{
            border-right: 1px solid #292b2f;
       }
        
    }
    @media screen and (max-width:430px){
       .footer_container{
            max-width: 200%;
       }
       .footer_links{
            flex-basis: 100%;
       }
       .footer_contact{
            flex-basis: 100%;
       }
       
    }
   