/* schedulemeet.css */

/* General reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.scheduleform {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%; /* Take full width */
  max-width: 600px; /* Limit maximum width */
  padding: 20px; /* Adjust padding */
  border: 2px solid black;
  border-radius: 38px;
  margin-top: 7%;
}

.scheduleform > h1 {
  text-align: center; /* Center align */
  margin-bottom: 20px; /* Adjust margin */
  font-size: 32px; /* Adjust font size */
}

.scheduleform > p {
  font-size: 1.5rem;
  text-align: justify;
  margin: 1rem 0;
}

input[type='text'],
input[type='email'],
textarea {
  padding: 14px; /* Adjust padding */
  font-size: 1.5rem; /* Adjust font size */
  border-radius: 8px;
  border: none;
  background-color: #FFFFFF;
  margin-bottom: 10px; /* Adjust margin */
}

textarea {
  height: 150px; /* Adjust height */
}

.scheduleform button {
  padding: 14px 28px; /* Adjust padding */
  font-size: 18px; /* Adjust font size */
  border-radius: 58px;
  background: linear-gradient(to bottom, #6E75F9, #2B34D9);
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 12px; /* Adjust margin */
}

.error-message {
  color: red;
  margin-top: 6px; /* Adjust margin */
  font-size: 14px;
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .scheduleform > h1 {
    font-size: 28px;
  }

  .scheduleform > p {
    font-size: 16px;
  }

  input[type='text'],
  input[type='email'],
  textarea {
    font-size: 14px;
    padding: 12px;
  }

  .scheduleform button {
    padding: 12px 24px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .scheduleform > h1 {
    font-size: 24px;
  }

  .scheduleform > p {
    font-size: 14px;
  }

  input[type='text'],
  input[type='email'],
  textarea {
    font-size: 12px;
    padding: 10px;
  }

  .scheduleform button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

