
*,*:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing:border-box;
}

body{
    background: #e8e8e8;
}

.hero{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
}

.hs1container{
    position: relative;
    background-color: white;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding-top: 7%;
}

.left-arc{
    transform: scaleX(-1);
}

.hs1container h1,.hs1container span{
    font-family: "Poppins", sans-serif;
    color: #323232;
}

.wrapper{
    /* position:absolute; */
    height:3rem;
    width:100%;
    line-height: 1.15;
    font-size: 5rem;
    left: 50%; 
    /* transform: translateX(-50%); */
    top:10%;
}

.wrapper h1{
    margin: .5rem 1.5rem;
    font-size: 90%;
    margin-top: 3rem;
    margin-left: -3rem;
}
.head1-first-line{
    display: flex;
    flex-direction: column;
    min-width: 60%;
}
.head1-first-line h1{
    font-size: 4rem;
    align-items: center;
    gap: 32px
}
.head1-first-line span{
    font-size: 4rem;
    color: #3967E9;

}
.words{
    /* transform: translateY(15px); */
    display: inline-flex;
    border: 0px solid #ddd; 
    height: 5rem;
    line-height: 1;
    padding-bottom: 1rem;
    /* overflow: hidden; */
    font-size: 100%;
    margin-left: 1rem;
    /* flex-direction: column; */
}

.words span{
    position: relative;
    /* animation: animation 30s ease infinite; */
}


.para{
    /* position: absolute; */
    /* bottom: 50%;
    left: 50%;
    width: 100%; */
    /* transform: translate(-50%,50%); */
    color: #323232;
    font-size: 1.5rem;
    padding-top: 1rem;
    text-align: justify;
}

.header-btn {
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    /* position: absolute; */
    /* left: 51%; */
    /* transform: translate(-50%,150%); */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: max-content;
    height: min-content;
    background: linear-gradient(#4383FF, #0C4ECC);
    border-radius: 3rem;
    border: none;
    outline: none;
    padding: 1rem 1.5rem ;
    margin-top: 2rem;
    /* margin-top: 20rem; */
}



.header-btn span {
    font-family: "Poppins", sans-serif;
    color: white;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    width: 0;
    transition: opacity 900ms ease-in, visibility 0ms linear 500ms, width .5s linear, opacity 100ms ease-out;
}

.header-btn:hover span {
    font-family: "Poppins", sans-serif;
    color: white;
    opacity: 1;
    visibility: visible;
    border-radius: 1.25rem;
    width: 200px;
    transition: opacity 1.5s, visibility 1000ms linear, width .5s linear;
}

body{
    overflow: auto !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
}

body::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 834px){
    .wrapper h1{
        font-size: 2rem;
        margin-left: 1rem;
    }
    .hs1container{
        padding-top: 30%;
        padding-bottom: 30%;
    }
    .head1-first-line{
        width: 80%;
        margin-left: 5%;
        gap: 20px;
    }
    .head1-first-line h1{
        font-size: 3rem;
        margin-left: -7%;
        display: contents;
        gap: 20px;
    }
    .head1-first-line span{
        font-size: 3rem;
        margin-top: 52px;
        margin-left: -20%;    
    }
    .para p{
        width: 86%;
        font-size: .8rem;
        margin-top: -12%;
        /* padding-top: 36rem; */
        
    }
    .words{
        margin-left: 1rem;
    }
    .header-btn {
        font-size: 1rem;
        /* position: absolute; */
        /* left: 51%; */
        width: max-content;
        height: max-content;
        padding: 0 1rem ;
        margin-top: 14rem;
        margin-bottom: 10rem;
    }
    .header-btn > span{
        padding: .8rem 0;
    }
  }


  @media screen and (max-width: 430px){

    .wrapper h1{
        font-size: 25px;

    }
    .head1-first-line{
        width: 90%;
        margin-left: -7%;
        margin-top: 50%;
        width: 110%;
    }
    .head1-first-line h1{
        font-size: 2rem;
        margin-left: 4.5rem;
        /* margin-top: -5rem; */
    }
    .head1-first-line span{
        font-size: 2rem; 
        margin-top: 0.1rem;   
        margin-left: 0.1rem;
    }
    .words{
        margin-left: -1rem;
    }
    .para p{
        width: 90%;
        font-size: .8rem;
    }
    .header-btn {
        font-size: 1rem;
        margin-top: 2rem;
     
    }

    .header-btn > span{
        padding: .8rem 0;
    }
    .overall{
        margin-top: -15rem;
    }
  }

  @media only screen and (max-width: 1024px ){
    .overall{
        padding-top: 10rem;
    }
  }

  @media screen and (max-width: 320px){
    .head1-first-line span{
        margin-left: 6px;
        margin-top: 5rem;
    }
    .para{
        margin-top: 50px;
    }

    .header-btn > span{
        padding: .8rem 0;
    }
  
  }