:root {
  --dark-body: #4d4c5a;
  --dark-main: #141529;
  --dark-second: #79788c;
  --dark-hover: #323048;
  --dark-text: #f8fbff;

  --light-body: #f3f8fe;
  --light-main: #fdfdfd;
  --light-second: #c3c2c8;
  --light-hover: #edf0f5;
  --light-text: #151426;

  --blue: #2d2d2d;
  --white: #fff;

  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  --font-family: cursive;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App{
  display: flex;
  justify-content: center;
  width: 100%;
}

.calendar {
  height: max-content;
  width: max-content;
  position: relative;
  overflow: hidden;
}

.calendarmain{
  height: fit-content;
  width: fit-content;
  background-color: rgba(40, 45, 53, 1);
  color:rgba(255, 255, 255, 1);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.calendar-header {
  align-self: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 25px;
  padding-bottom: 14px;
}

.month-picker{
  background-color: rgba(40, 45, 53, 1);
  color:rgba(255, 255, 255, 1);
  border: none;
  margin-right: 20px;
  font-size: 25px;
  font-family: 'Onest', sans-serif;
  font-weight: 600;
}

#one{
  margin:0;
}
#two{
  margin:0;
}

.whiteline{
  align-self: center;
  margin: 0;
  width: -webkit-fill-available;
}

.month-picker> option{
  background-color: black;
  color: white;
}


.calendar-body {
  padding:0 30px 0 30px;
}

.calendar-week-day {
  height: 50px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-weight: 600;
}

.calendar-week-day div {
  font-family: Product Sans;
  font-weight: 500;
  display: grid;
  place-items: center;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
  place-items: center;
}


.calendar-days div.calendar-day {
  font-family: Product Sans;
  font-weight: 500;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  position: relative;
  animation: to-top 1s forwards;
  cursor: pointer;
}

.calendar-days > div.calendar-day.selected:not(.empty) {
  background-color: blue;
  color: rgb(255, 255, 255);
  border-radius: 50%;
}

.calendar-days div.non-selectable {
  color: rgb(255, 0, 0);
  cursor: not-allowed;
}

.calendar-days div span {
  position: absolute;
}

.calendar-days div:hover span {
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
}

.calendar-days div span:nth-child(1),
.calendar-days div span:nth-child(3) {
  width: 2px;
  height: 0;
  background-color: var(--color-txt);
}

.calendar-days div:hover span:nth-child(1),
.calendar-days div:hover span:nth-child(3) {
  height: 100%;
}

.calendar-days div span:nth-child(1) {
  bottom: 0;
  left: 0;
}

.calendar-days div span:nth-child(3) {
  top: 0;
  right: 0;
}

.calendar-days div span:nth-child(2),
.calendar-days div span:nth-child(4) {
  width: 0;
  height: 2px;
  background-color: var(--color-txt);
}

.calendar-days div:hover span:nth-child(2),
.calendar-days div:hover span:nth-child(4) {
  width: 100%;
}

.calendar-days div span:nth-child(2) {
  top: 0;
  left: 0;
}

.calendar-days div span:nth-child(4) {
  bottom: 0;
  right: 0;
}

.calendar-days div:hover span:nth-child(2) {
  transition-delay: 0.2s;
}

.calendar-days div:hover span:nth-child(3) {
  transition-delay: 0.4s;
}

.calendar-days div:hover span:nth-child(4) {
  transition-delay: 0.6s;
}

.calendar-days div.curr-date {
  background-color: black;
  color: var(--white);
  border-radius: 50%;
}

.calendar-days div.curr-date span {
  display: none;
}

.month-picker {
  padding: 0;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.month-picker:focus {
  outline: none;
  border-color: transparent;
}

.year-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.year-change {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.year-change:hover {
  background-color: var(--color-hover);
}


.month-list {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--bg-main);
  padding: 20px;
  grid-template-columns: repeat(3, auto);
  gap: 5px;
  display: grid;
  transform: scale(1.5);
  visibility: hidden;
  pointer-events: none;
}

.month-list.show {
  transform: scale(1);
  visibility: visible;
  pointer-events: visible;
  transition: all 0.2s ease-in-out;
}

.month-list > div {
  display: grid;
  place-items: center;
}

.month-list > div > div {
  width: 100%;
  padding: 5px 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  color: var(--color-txt);
}

.month-list > div > div:hover {
  background-color: var(--color-hover);
}

@keyframes to-top {
  0% {
      transform: translateY(100%);
      opacity: 0;
  }
  100% {
      transform: translateY(0);
      opacity: 1;
  }
}

@media only screen and (min-width:360px) and (max-width: 580px) {
   .calendarmain{
    width: 320px;
   }

   .calendar-header, .month-picker {
      font-size: 1rem;
  }
  .calendar-days div.calendar-day{
    width: 30px;
    height: 30px;
  }

  .calendar-body{
    padding: 1rem;
  }

}




@media screen and (min-width:900px) and (max-width:1000px) {

  .calendarmain{
    width: 400px;
   }

   .calendar-header, .month-picker {
      font-size: 1.5rem;
  }
  .calendar-days div.calendar-day{
    width:40px;
    height: 40px;
  }

  .calendar-body{
    padding: 1rem;
  }

  .scheduling{
    flex-direction: column;
  }

  .second > img{
    width: 30px;

   }
   .ccontent>.second{
    font-size: 1.2rem;
    
   }
}