.heading{
    width: 90%;
    height: 40%;
    margin-top: 10%;
    display: flex;
    justify-content: space-between;
    margin-left: 3%;
}
.headtext{
    width: 50%;
    height: 227px;
    margin-left: 5%;
}
.headtext h1{
    font-family: "Poppins", sans-serif;
    font-size: 58px;
    font-weight: 600;

}
.headtext p{
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-align: left;    
    margin-top: 5%;
    margin-bottom: 5%;

}
.btn{
    font-family: "Poppins", sans-serif;  
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    width: 180px;
    height: 52 px;
    padding: 16px 24px 16px 24px;
    gap: 4px;
    border-radius: 100px ;
    background: #3967E9;
    border: none;
    cursor: pointer;
}

.blogimg1 {

    height: 500px; 
    display: flex;
    justify-content: center;
    align-items: center; 
    border-radius: 20px;
    overflow: hidden; 
    margin-left: 5px;
}


.process{
    width: 85%;
    height: auto;
    margin-top: 200px;
    margin-left: 5%;
}
.process h1{
    font-family: "Poppins", sans-serif;
    font-size: 54px;
    font-weight: 600;
}
.steps{
    width: 95%;
    margin-left:5% ;

}
.oddstep{
    /* width: 100%;
    height: 50%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 60px;
    margin-left: 2%;
    align-items: center;
}
.oddstep-text{
    width: 523px;
    height: 180px;
    margin-top: 80px;
    background-color:  #e8e8e8;
    border-radius: 40px;
    border: 2px solid #2121;
    align-items: center;
    text-align: center;
}
.oddstep-text p{
    text-align: justify;
    font-size: 26px;
    font-weight: 500;
    /* padding: 5%; */
    align-content: center;
}
.count1{
    width: 410px;
    height: auto;
    margin-top: 60px;
    align-content: center;
}
.count1 h1{
    width: 100%;
    height: 180px;
    font-size: 150px;
    font-weight: 700;
    color: #428CF7;
    align-items: center;
    text-align: center;

}
.count1 h6{
    font-family: "Poppins", sans-serif;
    font-size: 60px;
    font-weight: 400;
    text-align: center;
}


.evenstep{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    margin-left: 2%;
    margin-bottom: -5%;
    align-items: center;
}
.evenstep-text{
    width: 523px;
    height: 180px;
    margin-top: 80px;
    background-color: #F4F4F4;
    border-radius: 40px;
    border: 2px solid black;
    align-items: center;
    text-align: center;
}
.evenstep-text p{
    text-align: justify;
    font-size: 26px;
    font-weight: 500;
    padding: 5%;
    margin-top: -12px;
}
.count2{
    width: 410px;
    height: auto;
    margin-top: 60px;
    align-content: center;
}
.count2 h1{
    width: 100%;
    height: 180px;
    font-size: 150px;
    font-weight: 700;
    color: #428CF7;
    align-items: center;
    text-align: center;

}
.count2 h6{
    font-family: "Poppins", sans-serif;
    font-size: 60px;
    font-weight: 400;
    text-align: center;
}



.oddstep{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    margin-left: 2%;
    flex-direction: row;
}
.oddstep-text{
    width: 523px;
    height: 180px;
    margin-top: 80px;
    background-color: #F4F4F4;
    border-radius: 40px;
    border: 2px solid black;
    align-items: center;
    text-align: center;
}
.oddstep-text p{
    text-align: justify;
    font-size: 26px;
    font-weight: 500;
    /* padding: 5%; */
    align-content: center;
}
.count3{
    width: 410px;
    height: auto;
    margin-top: 60px;
    align-content: center;
}

.count3 h1{
    width: 100%;
    height: 180px;
    font-size: 150px;
    font-weight: 700;
    color: #428CF7;
    align-items: center;
    text-align: center;
}
.count3 h6{
    font-family: "Poppins", sans-serif;
    font-size: 60px;
    font-weight: 400;
    text-align: center;
}



.evenstep{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-left: 2%;
    margin-bottom: -5%;
    align-items: center;
}
.evenstep-text{
    width: 100%;
    height: 180px;
    margin-top: 80px;
    background-color: #F4F4F4;
    border-radius: 40px;
    border: 2px solid black;
    align-items: center;
    text-align: center;
}
.evenstep-text p{
    margin-top: 2px;
    font-size: 26px;
    font-weight: 500;
    padding: 5%;
    margin-left: 10px;
}
.count4{
    width: 410px;
    height: auto;
    margin-top: 60px;
    align-content: center;
}

.count4 h1{
    width: 100%;
    height: 180px;
    font-size: 150px;
    font-weight: 700;
    color:#428CF7;
    align-items: center;
    text-align: center;

}
.count4 h6{
    font-family: "Poppins", sans-serif;
    font-size: 60px;
    font-weight: 400;
    text-align: center;
}

.oddstep{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-left: 2%;
    flex-direction: row;
}
.oddstep-text{
    width: 100%;
    height: 180px;
    margin-top: 80px;
    background-color: #F4F4F4;
    border-radius: 40px;
    border: 2px solid black;
    align-items: center;
    text-align: center;
}
.oddstep-text p{
    font-size: 26px;
    font-weight: 500;
    padding: 5%;
    align-content: center;
    margin-left: 10px;
}
.count5{
    width: 410px;
    height: auto;
    margin-top: 60px;
    align-content: center;
}

.count5 h1{
    width: 100%;
    height: 180px;
    font-size: 150px;
    font-weight: 700;
    color: #428CF7;
    align-items: center;
    text-align: center;
}
.count5 h6{
    font-family: "Poppins", sans-serif;
    font-size: 60px;
    font-weight: 400;
    text-align: center;
}



.toolhead{
    margin-top: 150px;
    margin-left: 100px;
    margin-bottom: 80px;
}
.toolhead h1{
    font-family: "Poppins", sans-serif;
    font-size: 54px;
    font-weight: 400;
    text-align: left;

}
.tools{
    height: fit-content;
    margin-top: 100px;
    
}
.icon{
    width: 90%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    margin-top: 10%;
}
.icon img{
    width: 100px;
    height: 100px;
}







@media only screen and (max-width: 834px) {
    .heading{
        display: block;
        margin-top: 1rem;
    }
    .headtext{
        width: 80%;
    }
    .headtext h1{
        font-size: 36px;
    }
    .headtext p{
        font-size: 16px;
    }
    .process{
        margin-top: 150px;
    }
    .process h1{
        font-size: 38px;
    }
    .blogimg1{
        width: 90%;
        margin-left: 5%;
        margin-top: 100px;
    }
    .oddstep{
        display: flex; 
        flex-direction: column-reverse;
        margin-left: 0%;
    }
    .oddstep-text{
        height: auto;
    }
    .oddstep-text p{
        font-size: 15px;
    }
    .evenstep{
        display: flex;
        flex-direction: column;
    }
    .evenstep-text{
        height: auto;
    }
    .evenstep-text p{
        font-size: 15px;
    }

    .count2{
        width: 100%;
        text-align: center;
    }
    .count4{
        width: 100%;
        text-align: center;
    }
    .count1 h1{
        font-size: 84px;
        height: auto;
    }
    .count1 p{
        font-size: 84px;
    }
    .count2 h1{
        font-size: 84px;
        height: auto;
    }
    .count2 p{
        font-size: 84px;
    }
    .count3 h1{
        font-size: 84px;
        height: auto;
    }
    .count2 p{
        font-size: 84px;
    }
    .count4 h1{
        font-size: 84px;
        height: auto;
    }
    .count4 p{
        font-size: 84px;
    }
    .count5 h1{
        font-size: 84px;
        height: auto;
    }
    .count5 p{
        font-size: 84px;
    }
    .toolhead{
        margin-left: 10px;
    }
    .toolhead h1{
        font-size: 38px;
    }
    .icon img{
        width: 60px;
        height: 60px;
    }
    
}

@media only screen and (max-width: 430px){
    .steps{
        margin-left: 0px;
    }
    .heading{
      margin-top: 3rem;
    }
    .headtext h1{
        font-size: 28px;
    }
    .headtext p{
        font-size: 16px;
    }
    .blogimg1{
        height: auto;
    }
    .blogimg1 img{
        width: 100%;
        height: 50%;
        margin-left: -5rem;
        margin-top: 20px;
        border-radius: 20px;
    }
    .process{
        margin-top: 50px;
    }
    .process h1{
        font-size: 40px;
    }
    .oddstep-text{
        width: 100%;
        margin-top: 30px;
    }
    .oddstep-text p{
        font-size: 18px;
        text-align: left;
    }
    .evenstep-text{
        width: 100%;
        margin-top: 30px;
    }
    .evenstep-text p{
        font-size: 18px;
        text-align: left;
    }
    .count2{
        width: 100%;
        text-align: center;
    }
    .count4{
        width: 100%;
        text-align: center;
    }
    .count1 h1{
        font-size: 56px;
        height: auto;
    }
    .count1 p{
        font-size: 56px;
    }
    .count2 h1{
        font-size: 56px;
        height: auto;
    }
    .count2 p{
        font-size: 56px;
    }
    .count3 h1{
        font-size: 56px;
        height: auto;
    }
    .count2 p{
        font-size: 56px;
    }
    .count4 h1{
        font-size: 56px;
        height: auto;
    }
    .count4 p{
        font-size: 56px;
    }
    .count5 h1{
        font-size: 56px;
        height: auto;
    }
    .count5 p{
        font-size: 56px;
    }
    .toolhead{
        margin-left: 5%;
    }
    .toolhead h1{
        font-size: 38px;
    }
    .icon img{
        width: 60px;
        height: 60px;
    }
    .count1 h6{
        font-size: 4rem;
    }
    .count2 h6{
        font-size: 4rem;
    }
    .count3 h6{
        font-size: 4rem;
    }
    .count4 h6{
        font-size: 4rem;
    }
    .count5 h6{
        font-size: 4rem;
    }
}

