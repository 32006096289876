* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.navbar-container-desktop {
  width: 55%;
  left: 23%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5% auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 1.875rem;
  z-index: 100;
  transition: transform 0.5s ease, box-shadow 0.5s ease, height 0.5s ease !important;
  cursor: pointer;
  position: fixed; 
  top: 2%; /* Adjust as needed */
}

.navbar-container-desktop:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 20px rgba(65, 105, 225, 0.5), 0 0 30px rgba(65, 105, 225, 0.3);
}

.navlogo-content-desktop {
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  margin: auto auto;
  transition: opacity 0.3s ease, visibility 0.5s ease;
}

.logo-desktop {
  display: flex;
  align-items: center;
 
}
.logo-desktop img{
  height: 50px;
  width: 50px;
  margin: 0 0px 10px 0px;
}
.white-text {
  color: black;
  font-weight: 600;
  font-size: 24px;
}

.purple-text {
  color: #3967E9;
  font-weight: 600;
  font-size: 24px;
}

/* span{
  mix-blend-mode: difference;
} */


.nav-links-desktop a {
  text-decoration: none;
  position: relative;
  transition: transform 0.2s;
  margin: 0 37px;
}

.nav-links-desktop a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1.5px solid transparent;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  pointer-events: none;
  z-index: -1;
}

.Nav1text,
.Nav2text,
.Nav3text,
.Nav4text,
.Nav5text {
  color: black;
  
}

.Nav1text:hover,
.Nav2text:hover,
.Nav3text:hover,
.Nav4text:hover,
.Nav5text:hover {
  color: #3967E9;
  transition: 0.3s ease-in-out;
}

.nav-links-desktop {
  display: flex;
  align-items: center;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  width: 85%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, height 0.3s ease;
  z-index: 4;
}

.dropdown-content.visible {
  background:transparent;
  transition: opacity 3s ease, visibility 3s ease, height 3s ease;
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.navbar-container-desktop:hover + .dropdown-content {
  height: 12rem;
}

.dropdown-rows {
  display: flex;
  flex-direction: column;
}

.dropdown-content a {
  text-decoration: none;
  color: black;
  line-height: 2;
}

.dropdown-content a:hover {
  color: #3967E9;
}

.dropdown-content a:hover .logo-navbar-services {
  color: #3967E9;
}

.logo-navbar-services {
  color: black;
  font-size: 1rem;
  margin: 0.5rem;
  vertical-align: middle;
}

/* General styles for mobile navbar */
.navbar-container-mobile {
  width: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.18); 
}

.navlogo-content-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5px auto;
}

.logo-mobile img{
  height: 50px;
  width: 170px;
}

.burger-menu {
  height: 100%;
  width: 2.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  z-index: 2;
}

.burger-bar {
  width: 2em;
  height: 0.2em;
  background-color:black;
  border-radius: 0.5em;
}

.menu {
  width: 100%;
  height: 100vh;
  position: absolute; 
  top: 16px;
  left: 0;
  z-index: 1;
  display: none; 
  transform: ease-in-out 0.5s;
}

.menu.visible {
  display: block;
  transition: ease-in-out 0.5s;
}

.burger-bar.clicked:nth-child(1) {
  transform: rotate(47deg) translate(0.5em, 0.5em);
  transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(2) {
  transform: scale(0);
  transition: ease-out 0.2s;
}

.burger-bar.clicked:nth-child(3) {
  transform: rotate(135deg) translate(-0.7em, 0.65em);
  transition: ease-out 0.5s;
}

.burger-bar.unclicked {
  transform: rotate(0) translate(0);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

.nav-links-mobile a {
  text-decoration: none;
  position: relative;
  transition: transform 0.2s;
  margin: 10px 0; /* Ensure spacing between links */
  display: block; /* Block display for full-width links */
  font-size: 22PX;
}

.nav-links-mobile {
  padding: 20px;
  position: relative;
  top: 78px;
  background-color: aliceblue;
}

.Nav1text-mobile,
.Nav2text-mobile,
.Nav3text-mobile,
.Nav4text-mobile,
.Nav5text-mobile {
  color: black;
  display: block; /* Block display for full-width links */
}
.Nav1text-mobile:hover,
.Nav2text-mobile:hover,
.Nav3text-mobile:hover,
.Nav4text-mobile:hover,
.Nav5text-mobile:hover {
  color: #3967E9;
  transition: 0.3s ease-in-out;
}


.dropdown-rows-mobile a {
  color: black;

}

.dropdown-rows-mobile a:hover {
  color: #3967E9;
}

/* Specific styles for different screen sizes */

@media (max-width:1024px) {
  nav-links-desktop a {
    margin: 0 10px;
  }
  
}
 @media (max-width: 835px) {
  .navbar-container-mobile {
    padding: 10px;
    width: 100%;
  }

  .burger-menu {
    display: flex;
  }

  .menuClass {
    width: 100%;
  }
 }

@media (max-width:1024px) {

  .navbar-container-desktop {
    width: 80%; /* Adjust width for smaller screens */
    left: 10%; /* Adjust left for smaller screens */
  }

}
 @media (max-width: 835px) {
  .navbar-container-mobile {
    padding: 10px;
    width: 100%;
  }

  .burger-menu {
    gap: 10px;
  }

  .menuClass {
    width: 100%;
  }
 }
  


 @media screen and (max-width: 834px) {
  .logo-mobile img{
    width: 60px;
    height: 65px;
    margin-top: -.5rem;
    padding-top: 10px;
    z-index: 2;
    position: relative;
  }
 }