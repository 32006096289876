.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #e8e8e8;
    margin-top: -85px;
    width: 100%;
  }
  
  .error-container {
    background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .error-code {
    font-size: 10em;
    margin: 0;
    color: #ffffff;
    animation: bounce 2s infinite;
  }
  
  .error-message {
    font-size: 1.5em;
    margin: 20px 0;
    color: #ffffff;
  }
  
  .back-home {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 25px;
    font-size: 1em;
    color: #ffffff;
    text-decoration: none;
    background-color: #ff6f61;
    border-radius: 25px;
    transition: background-color 0.3s ease;
  }
  
  .back-home:hover {
    background-color: #ff4b47;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }